<template>

  <div class="main main-scroll btm-menu">
    <div tabindex="-1" class="main-sidebar">
      <div class="main-sidebar__head">
        <h4 class="title">Suche</h4>
      </div>

      <div class="main-sidebar__content none" v-if="!$isMobileScreen">

        <keyword-search class="mb20" :initialValue="dataSet.query.keyword" @searchWordChanged="searchWordChanged" ref="searchBox"/>

        <simple-select-field class="mb20" name="Was suchst du?" field-type-id="contentType" :values="contentTypeSelectValues" :isMultiple="false" @on-select="contentTypeChanged" initialValue="Angebote"/>

        <anbieter-filter v-if="contentType!==null && currentContentTypeId==1 && sublocalityFilter!==null" :contentType="contentType" :selectedFilters="selectedFilters" :structuredFilters="structuredFilters" :sublocalityFilter="sublocalityFilter" @filterChanged="setQueryFilters" @resetAllFilters="resetAllFilters"/>

        <angebote-filter v-if="contentType!==null && currentContentTypeId==2 && sublocalityFilter!==null" :contentType="contentType" :selectedFilters="selectedFilters" :structuredFilters="structuredFilters" :sublocalityFilter="sublocalityFilter" @dateTimeChanged="setDateQuery" @filterChanged="setQueryFilters" @resetAllFilters="resetAllFilters"/>

        <div class="clearfix"></div>

      </div>
    </div>

    <div tabindex="-1" class="main-content">
      <TitleBar v-if="contentType!==null && $isMobileScreen" :filters="allFilters" :selectedFilters="selectedFilters" :showContentTypeSelect="true" :contentTypeSelectValues="contentTypeSelectValues"  :showTermin="contentType.id==2" :showToggleFilters="contentType.id==2" :isFixed="true" :contentCount="tempContentCount" :initialKeywordSearchValue="dataSet.query.keyword" :initialContentType="contentType.id==1?'Einrichtungen':'Angebote'" @contentFilteringFinished="filteringFinished" @resetAllFilters="resetAllFilters" @getNumberOfResults="getNumberOfResults" @searchWordChanged="searchWordChanged" @changedTimeFilters="setDateQuery" @contentTypeChanged="contentTypeChanged"/>

      <div class="section content-section">
        <div class="container-fluid" v-if="dataSet.total>0">
          <div class="row">
            <div class="col-12">
              <h5 class="mb20">{{dataSet.total}} Ergebnisse</h5>
            </div>
          </div>
          <div class="offer-row row" v-if="dataSet.data!==null && dataSet.data.length>0">
            <div class="col-12 col-md-6 col-lgs-6 col-lg-6 col-xls-6 col-xl-4 d-flex align-items-stretch" v-if="currentContentTypeId == 2" v-for="(item, index) in dataSet.data" :key="index">
              <angebot-card :item="item" :noslider="true"/>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 cards" v-if="currentContentTypeId == 1" v-for="(item, index) in dataSet.data" :key="`key${index}`">
              <anbieter-card :item="item" :autoWidth="true"/>
            </div>

          </div>
          <pagination v-if="dataSet.total>12" break-view-link-class="break-style" :page-count="dataSet.meta.last_page" :click-handler="changePage" containerClass="pagination" prev-text="&laquo" next-text="&raquo"/>
          </div>
          <div v-else class="search-no-results">
            <img src="/assets/search-no-result.png" alt="Keine Ergebnisse gefunden">
            <h5 class="mt20">Keine Ergebnisse gefunden</h5>
          </div>
        </div>
      </div>

    </div>
  </template>

  <script>
    import Resource from '@/api/resource';
    import { getSublocalities } from '@/api/system';
    import { getContentsOfType } from '@/api/content';
    import { getCtype } from '@/api/contentType';
    const contentTypeResource = new Resource('contenttypes');
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';
    import { showLoader, hideLoader, isset, getStructuredFilters } from '@/utils/helpers';
    import { addZero, getReadableTime, getFilterDate, compareAndSwitchDates, getWeekday, getMonth } from '@/utils/appointment-helpers';

    export default {
      name: 'Suche',
      mixins: [screenSizeMixin],
      components: {
        TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
        ContentSection: () => import('./components/ContentSection.vue'),
        KeywordSearch: () => import('@/components/controls/KeywordSearch'),
        SimpleSelectField: () => import('@/components/controls/SimpleSelectField.vue'),
        AngeboteFilter: () => import('@/components/filters/angebote.vue'),
        AnbieterFilter: () => import('@/components/filters/anbieter.vue'),
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        AnbieterCard: () => import('@/components/cards/Ort.vue'),
        Pagination: () => import('@/components/controls/Pagination'),
      },
      data() {
       return {
        loader: null,
        tempShowHide: true,
        isMobileView: false,
        searchWord: '',
        sublocalities: null,
        showResults: false,
        awaitingSearch: false,
        numberOfResults: 0,
        tempContentCount: 0,
        contentType: null,
        selectedFilters: new Map(),
        currentContentTypeId: this.$offerId,
        contentTypeSelectValues: [
          { label: "Angebote", value: this.$offerId },
          { label: "Einrichtungen", value: this.$institutionId }
          ],
        dataSet: {
          total: 0,
          data: [],
          meta:null,
          query: {
            page:1,
            type: "view",
            limit: 12,
            keyword: '',
            filters: null,
            selectedEndDate: null,
            selectedStartDate: null,
            selectedStartTime: null,
            appId: this.$appId,
            selectedEndTime: null,
            selectedContentTypeId: this.$offerId,
            view_status: 1
          },
        },
      }
    },
    watch: {
      '$route.query.keyword': {
        handler: function(search) {
         this.searchWordChanged(search);
       },
       deep: true,
       immediate: true
     },
     $screenWidth(newWidth) {
      if(newWidth<=991){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    },
    isMobileView(newValue,oldValue){
      /*resetting the filters if the view changes from mobile to desktop*/
      if(newValue!==oldValue){
        this.resetAllFilters();
        this.selectedFilters=new Map();
      }
    }
  },
  mounted() {
    this.getContentType();
  },
  computed: {
    sublocalityFilter(){
      if(this.sublocalities!==null){
        var bla = {};
        bla.field_type_id="location";
        bla.label = "Stadtteile";
        bla.options = []
        for(var i = 0; i < this.sublocalities.length; i++){
          bla.options.push({label:this.sublocalities[i].sublocality,value:this.sublocalities[i].sublocality});
        }
        return bla;
      }
      return null;
    },
    structuredFilters(){
     return getStructuredFilters(this.contentType);
   },
   allFilters(){
     return this.structuredFilters.concat(this.sublocalityFilter); 
   },
   keyword(){
    return this.$route.query.keyword;
  },
},
methods: {
  showLoader,
  hideLoader,
  filteringFinished(map){
    /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
    this.selectedFilters = map;
    this.dataSet.query.page = 1;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
  },
  changePage(page){
    this.dataSet.query.page = page;
    this.getContents();
  },
  setDateQuery(dateTimeSelection){
    this.dataSet.query.page = 1;
    this.dataSet.query.selectedStartDate = getFilterDate(dateTimeSelection.selectedStartDate);
    this.dataSet.query.selectedEndDate = getFilterDate(dateTimeSelection.selectedEndDate);
    this.dataSet.query.selectedStartTime = getReadableTime(dateTimeSelection.selectedStartTime);
    this.dataSet.query.selectedEndTime = getReadableTime(dateTimeSelection.selectedEndTime);
    this.getContents();
  },
  receiveNumberOfResults(number){
    this.numberOfResults = this.numberOfResults+number;
  },
  searchWordChanged(word){
    this.dataSet.query.page = 1;
    this.dataSet.query.keyword = word;
    this.getContents();
  },
  contentTypeChanged(value){
    this.dataSet.query.page = 1;
    this.currentContentTypeId = value.value;
    this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
    this.getContentType();
    this.resetAllFilters();
  },
  setQueryFilters(){
    this.dataSet.query.page = 1;
    this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    this.getContents();
  },
  resetAllFilters(){
    this.selectedFilters = new Map;
    this.dataSet.query.selectedStartDate = null;
    this.dataSet.query.selectedEndDate = null;
    this.dataSet.query.selectedStartTime = null;
    this.dataSet.query.selectedEndTime = null;
    this.setQueryFilters();
  },
  async getTheSublocalities() {
    var id = this.currentContentTypeId;
    getSublocalities(this.$city,null, id).then(response => {
      this.sublocalities = response;
    });
  },
  getContentType() {
    this.contentType = null;
    var id = this.currentContentTypeId;
    this.loader = this.showLoader(this.loader);
    /*overwrite the existing filters*/
    getCtype(id,this.$store.getters.getLanguage).then(response => {
      this.contentType = response.data;
    })
    .finally(() => {
      this.loader = this.hideLoader(this.loader);
      this.getTheSublocalities();
      this.getContents();
    });
  },
  getContents() {
    /*test to see if that influences the back-button*/
    /*könnte man auch für alle Filter machen - dann müsste man da aber wieder eine Funktion schreiben die das Dingens zurück verwandelt in ein Objekt das aussieht wie der Query ...
    changeRouteQuery(this.$route, this.dataSet.query.filters);*/

    this.dataSet.query.type="view";
    var contentLoader = this.showLoader(contentLoader);
    const { limit, page } = this.dataSet.query;
    getContentsOfType(this.currentContentTypeId,this.dataSet.query, this.$store.getters.getLanguage).then(response => {
      const { data, meta } = response;
      this.dataSet.data = data;
      this.dataSet.data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.dataSet.meta = meta;
      this.dataSet.total = meta.total;
      this.tempContentCount = meta.total;
    })
    .finally(() => {
      contentLoader = this.hideLoader(contentLoader);
      this.tempShowHide = true;
    });
  },
  getNumberOfResults(map){
    /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
    var tempFilters = new Map([...this.selectedFilters,...map]);
    /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
    this.getNumberOfContentsMatchingTheFilter(tempFilters);
  },
  getNumberOfContentsMatchingTheFilter(tempFilters){
    this.dataSet.query.type="count";
    this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
    var contentLoader = this.showLoader(contentLoader);
    getContentsOfType(this.currentContentTypeId,this.dataSet.query, this.$store.getters.getLanguage).then(response => {
      this.tempContentCount = response;
    })
    .finally(() => {
      contentLoader = this.hideLoader(contentLoader);
    });
  },
}
}
</script>
<style lang="scss" scoped>
  @import '@/scss/_mixins.scss';
  @import '@/scss/_variables.scss';

  .content-section{
    @media (max-width: 991px) {
      margin-top: 180px;
    }
  }

  .cards {
    @media (max-width: 1600px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media (min-width: 992px) and (max-width: 1250px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (max-width: 750px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .search-view{
    margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});

    @media (max-width: 991px) {
      margin-bottom: #{$mobile-menu-size}
    }

    .main-sidebar{
      margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});  
    }

    .guide-article__top-img{
      @media (max-width: 991px) {
        height: 150px;
        width: 150px;
      }
      
      @media (max-width: 660px) {
        min-width: 150px;
      }
    }

  }

  .search-no-results {
    margin-top: 80px;
    text-align: center;
  }

</style>